import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export type SelectItem = {
  value: string | number;
  label: string | number;
  disabled?: boolean;
};

type Props = {
  name: string;
  selectItems: SelectItem[];
} & SelectProps;

export default function SelectBox(props: Props) {
  const { name, selectItems, defaultValue, label } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControl>
          {label && <InputLabel>{label}</InputLabel>}
          <Select labelId="level-label" {...field} {...props}>
            {selectItems.map((selectItem) => (
              <MenuItem
                value={selectItem.value}
                disabled={selectItem.disabled}
                selected={selectItem.value === defaultValue}
              >
                {selectItem.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
