import { Box } from '@mui/material';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CircularCore from '../components/Circular/CircularCore';
import CustomerDetail from '../pages/customer/detail/CustomerDetail';
import CustomerList from '../pages/customer/list/CustomerList';

import { PrivateRoute } from './ProtectedRoute';
const Login = lazy(() => import('../pages/login/Login'));
const TransactionList = lazy(() => import('../pages/transaction/list/List'));

const JudicialScrivenerInvitation = lazy(() => import('../pages/invitation/JudicialScrivenerInvitation'));
const Invitation = lazy(() => import('../pages/invitation/Invitation'));
const ConfirmationPage1dPage = lazy(() => import('../pages/transaction/detail/ConfirmationPage1d'));
const ConfirmationPage1cPage = lazy(() => import('../pages/transaction/detail/ConfirmationPage1c'));
const ConfirmationPage2b = lazy(() => import('../pages/transaction/detail/ConfirmationPage2b'));
const ConfirmationPage2c = lazy(() => import('../pages/transaction/detail/ConfirmationPage2c'));
const ConfirmationPage3a = lazy(() => import('../pages/transaction/detail/ConfirmationPage3a'));
const ConfirmationPage3b = lazy(() => import('../pages/transaction/detail/ConfirmationPage3b'));
const RequireItemEdit1cPage = lazy(() => import('../pages/transaction/edit/RequireItemEdit1cPage'));
const RequireItemEdit1dPage = lazy(() => import('../pages/transaction/edit/RequireItemEdit1dPage'));
const RequireItemEdit2bPage = lazy(() => import('../pages/transaction/edit/RequireItemEdit2bPage'));
const RequireItemEdit2cPage = lazy(() => import('../pages/transaction/edit/RequireItemEdit2cPage'));
const RequireItemEdit3aPage = lazy(() => import('../pages/transaction/edit/RequireItemEdit3aPage'));
const RequireItemEdit3dPage = lazy(() => import('../pages/transaction/edit/RequireItemEdit3dPage'));

export const Router: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
              <CircularCore />
            </Box>
          }
        >
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/invitation"
              element={
                <PrivateRoute>
                  <Invitation />
                </PrivateRoute>
              }
            />
            <Route
              path="/invitation/judicialscrivener"
              element={
                <PrivateRoute>
                  <JudicialScrivenerInvitation />
                </PrivateRoute>
              }
            />
            <Route
              path="/customers"
              element={
                <PrivateRoute>
                  <CustomerList />
                </PrivateRoute>
              }
            />
            <Route
              path="/customer/:userId"
              element={
                <PrivateRoute>
                  <CustomerDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction"
              element={
                <PrivateRoute>
                  <TransactionList />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/buyer1/detail"
              element={
                <PrivateRoute>
                  <ConfirmationPage1dPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/seller1/detail"
              element={
                <PrivateRoute>
                  <ConfirmationPage1cPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/buyer2/detail"
              element={
                <PrivateRoute>
                  <ConfirmationPage2b />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/seller2/detail"
              element={
                <PrivateRoute>
                  <ConfirmationPage2c />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/buyer3/detail"
              element={
                <PrivateRoute>
                  <ConfirmationPage3b />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/seller3/detail"
              element={
                <PrivateRoute>
                  <ConfirmationPage3a />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/buyer4/detail"
              element={
                <PrivateRoute>
                  <ConfirmationPage1dPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/seller4/detail"
              element={
                <PrivateRoute>
                  <ConfirmationPage1cPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/seller1/edit"
              element={
                <PrivateRoute>
                  <RequireItemEdit1cPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/buyer1/edit"
              element={
                <PrivateRoute>
                  <RequireItemEdit1dPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/seller2/edit"
              element={
                <PrivateRoute>
                  <RequireItemEdit2cPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/buyer2/edit"
              element={
                <PrivateRoute>
                  <RequireItemEdit2bPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/seller3/edit"
              element={
                <PrivateRoute>
                  <RequireItemEdit3aPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/buyer3/edit"
              element={
                <PrivateRoute>
                  <RequireItemEdit3dPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/seller4/edit"
              element={
                <PrivateRoute>
                  <RequireItemEdit1cPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction/:transactionId/buyer4/edit"
              element={
                <PrivateRoute>
                  <RequireItemEdit1dPage />
                </PrivateRoute>
              }
            />
          </Routes>
          {/* <Route component={React.lazy(() => import('~/components/Errors/Error/Error'))} /> */}
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default Router;
