import { AxiosError, AxiosResponse } from 'axios';
import { User, UserClassification } from '../model/User';
import ApiClient from './ApiClient';

const client = ApiClient();

export type ClassificationResponse = {
  result: boolean;
  user?: User;
};

//司法書士が担当している顧客一覧を取得する
export const fetchUsers = async (adminId: number) => {
  let resUsers: User[] = [];
  await client
    .get(`/admin/${adminId}/user`)
    .then((res: AxiosResponse<User[]>) => {
      const { data } = res;
      resUsers = data;
    })
    .catch((e: AxiosError<{ error: string }>) => {
      // エラー処理
      console.log(e.message);
    });
  return resUsers;
};

//ユーザ情報取得
export const getUserDetail = async (adminId: number, userId: number) => {
  let resUser = {} as User;
  await client
    .get(`/admin/${adminId}/user/${userId}`)
    .then((res: AxiosResponse<User>) => {
      const { data } = res;
      resUser = data;
    })
    .catch((e: AxiosError<{ error: string }>) => {
      // エラー処理
      console.log(e.message);
    });
  return resUser;
};

//ユーザ権限変更
export const changeUserClassification = async (adminId: number, id: number, param: UserClassification) => {
  let resUser = {} as User;
  await client
    .patch(`/admin/${adminId}/user/${id}`, param)
    .then((res: AxiosResponse<ClassificationResponse>) => {
      const { data } = res;
      resUser = data.user ?? ({} as User);
    })
    .catch((e: AxiosError<{ error: string }>) => {
      // エラー処理
      console.log(e.message);
    });
  return resUser;
};
