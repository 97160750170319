import { Grid, makeStyles } from '@material-ui/core';
import { Button, Card, CardContent, Stack } from '@mui/material';
import React, { memo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { changeUserClassification, getUserDetail } from '../../../api/UserApi';
import SelectBox from '../../../components/Form/SelectBox/SelectBox';
import { getUserId } from '../../../lib/auth';
import { BoxLabel } from '../../../lib/style/style';
import { User, UserClassification } from '../../../model/User';

const useStyles = makeStyles((theme) => ({
  centerColumn: {
    border: '1px solid #E6E6E6',
  },
}));

//顧客詳細
const ConfirmationPage1c = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const adminId = getUserId();
  const methods = useForm<UserClassification>();
  const { handleSubmit } = methods;
  const { userId } = useParams();

  const onSubmit: SubmitHandler<UserClassification> = (data) => {
    console.log(data);
    changeUserClassification(adminId, parseInt(userId ?? '0'), data)
      .then((data) => {
        setUser(data);
        alert('権限を更新いたしました。');
      })
      .catch((err) => {
        alert('権限の更新に失敗しました。');
        console.log(err);
      });
  };

  const [user, setUser] = React.useState<User>({} as User);

  React.useEffect(() => {
    getUserDetail(adminId, parseInt(userId ?? '0'))
      .then((data) => {
        setUser(data);
      })
      .catch((err) => {
        alert('ユーザ情報が存在しません。');
        console.log(err);
      });
  }, [adminId, userId, setUser]);
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="card">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.centerColumn}>
                  <BoxLabel>顧客詳細</BoxLabel>
                </Grid>
                {user && (
                  <>
                    <Grid item xs={6} className={classes.centerColumn}>
                      名前
                    </Grid>
                    <Grid item xs={6} className={classes.centerColumn}>
                      {user.name}
                    </Grid>
                    <Grid item xs={6} className={classes.centerColumn}>
                      メールアドレス
                    </Grid>
                    <Grid item xs={6} className={classes.centerColumn}>
                      {user.email}
                    </Grid>
                    <Grid item xs={6} className={classes.centerColumn}>
                      ユーザ権限
                    </Grid>
                    <Grid item xs={6} className={classes.centerColumn}>
                      {user.classification && (
                        <SelectBox
                          name="classification"
                          defaultValue={user.classification}
                          selectItems={[
                            { value: 'guest', label: 'ゲスト' },
                            { value: 'user', label: '仲介業者' },
                            { value: 'admin', label: '司法書士' },
                          ]}
                        />
                      )}
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={() => {
                            navigate('/customers', { replace: false });
                          }}
                        >
                          顧客一覧へ戻る
                        </Button>
                        <Button variant="contained" color="primary" size="large" type="submit">
                          権限を変更する
                        </Button>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}></Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </form>
      </FormProvider>
    </>
  );
};

export default memo(ConfirmationPage1c);
