import React, { memo } from 'react';
import { Table, TableContainer, TableBody, Button, Box, Grid, TableHead, TableCell, TableRow } from '@mui/material';
import { Stack } from '@mui/material';
import FormTextBox from '../../../components/Form/TextBox/TextBox';
import { BoxLabel, CustomerLayout } from '../../../lib/style/style';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { fetchUsers } from '../../../api/UserApi';
import { User } from '../../../model/User';
import { getUserId } from '../../../lib/auth';

const headerTitle: string[] = ['', 'ユーザ名', 'メールアドレス', '権限'];

const CustomerList = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const { handleSubmit } = methods;
  const onSubmit = (data: any) => console.log(data);

  const [users, setUsers] = React.useState<User[]>([]);

  const adminId = getUserId();

  React.useEffect(() => {
    fetchUsers(adminId)
      .then((data) => {
        setUsers(data);
      })
      .catch((err) => {
        alert('ユーザ情報が存在しません。');
        console.log(err);
      });
  }, [adminId]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ m: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BoxLabel>顧客一覧</BoxLabel>
              </Grid>
              <Grid item xs={9}></Grid>
              <Grid item xs={3}>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      navigate('/transaction', { replace: false });
                    }}
                  >
                    取引一覧へ戻る
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={2} alignContent="center">
                顧客名検索
              </Grid>
              <Grid item xs={6}>
                <FormTextBox name="searchKeyWord" placeholder="やりとり太郎"></FormTextBox>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </Box>
          <CustomerLayout>
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {headerTitle.map((title: string) => {
                      return (
                        <TableCell component="th" style={{ backgroundColor: '#f5f7f8', padding: '10px' }} align="left">
                          {title}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((uesr) => {
                    return (
                      <>
                        <TableRow>
                          <TableCell component="td" style={{ padding: '10px' }} align="center">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                navigate(`/customer/${uesr.id}`, { replace: false });
                              }}
                            >
                              編集
                            </Button>
                          </TableCell>
                          <TableCell component="td" style={{ padding: '10px' }} align="left">
                            {uesr.name}
                          </TableCell>
                          <TableCell component="td" style={{ padding: '10px' }} align="left">
                            {uesr.email}
                          </TableCell>
                          <TableCell component="td" style={{ padding: '10px' }} align="left">
                            {uesr.classification}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomerLayout>
        </form>
      </FormProvider>
    </>
  );
};

export default memo(CustomerList);
