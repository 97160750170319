import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

type RefreshToken = {
  access_token: string;
  user_id: number;
  token_type: string;
  expires_in: number;
};

export default function ApiClient() {
  let instance = axios.create({
    baseURL: baseURL,
    withCredentials: true,
  });
  instance.defaults.timeout = 15000;

  instance.interceptors.request.use(
    async (config) => {
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // レスポンスへの割り込み
  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const originalConfig = error.config;
      if (error.response) {
        if (error.response.status === 401 && !originalConfig._retry) {
          localStorage.clear();
          // await ApiClient()
          //  .post('/refresh')
          //  .then((res: AxiosResponse<RefreshToken>) => {
          //    localStorage.setItem('uid', res.data.user_id.toString());
          //    localStorage.setItem('exp', res.data.expires_in.toString());
          //  })
          //  .catch((err) => {
          //    localStorage.clear();
          //  });
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
}
