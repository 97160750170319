import React from 'react';
import { VFC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export const PrivateRoute: VFC<{ children: JSX.Element }> = ({ children }) => {
  const location = useLocation();
  const isRoutingAuthenticated = () => {
    const uid = Number(localStorage.getItem('uid'));
    const exp = Number(localStorage.getItem('exp'));

    if (!exp && !uid) {
      localStorage.clear();
      return false;
    }

    const currentTime: number = Date.now();
    if (currentTime > exp * 1000) {
      localStorage.clear();
      return false;
    }

    return true;
  };

  return isRoutingAuthenticated() ? children : <Navigate to="/login" state={{ from: location }} replace={false} />;
};
