import { Grid } from '@mui/material';
import styled from 'styled-components';

export const CustomerLayout = styled.html`
  background: #e6e6e6;
`;

export const Wrapper = styled.section`
  background: #e6e6e6;
  .card {
    width: 100%;
    text-align: center;
  }
`;

export const LoginLayout = styled.section`
  .card {
    width: 100%;
    text-align: center;
  }
`;

export const Logo = styled.span`
  font-size: 1.8em;
  font-weight: bold;
  text-align: center;
  color: #091747;
  padding: 10px;
`;

export const Label = styled.span`
  font-size: 1.4em;
  font-weight: bold;
  text-align: right;
`;

export const BaseButton = styled.button`
  font-weight: bold;
  text-align: center;
  color: white;
  width: 30%;
  padding: 10px;
  margin: 10px;
`;

export const BoxLabel = styled.div`
  font-size: 1.4em;
  font-weight: bold;
  text-align: center;
  color: white;
  background: #3a4d8f;
  padding: 5px;
`;

export const BoxSubLabel = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
  color: white;
  background: #3a4d8f;
  padding: 5px;
`;

export const BoxControlLabel = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
  color: white;
  background: #00ae95;
  padding: 5px;
`;

// 緑色のボタン(BaseButtonを基に拡張)
export const ButtonPrimary = styled(BaseButton)`
  background: #03b4c6;
`;

// 赤色のボタン(BaseButtonを基に拡張)
export const ButtonDanger = styled(BaseButton)`
  background: red;
`;

export const CustomGrid = styled(Grid)(({ theme }) => ({
  border: '1px solid #E6E6E6',
}));
