import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { Controller, get, useFormContext, ValidationRule, ValidationValueMessage } from 'react-hook-form';

export type FormTextBoxProps = TextFieldProps & {
  name: string;
  label?: string;
  rules?:
    | Partial<{
        required: string | boolean | ValidationValueMessage<boolean>;
        min: ValidationRule<React.ReactText>;
        max: ValidationRule<React.ReactText>;
        pattern: ValidationRule<RegExp>;
        valueAsNumber: boolean;
        valueAsDate: boolean;
      }>
    | undefined;
  onFocus?: () => void;
};

export const FormTextBox = (props: FormTextBoxProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const { name, label, required, rules, defaultValue, helperText, ...textFieldProps } = props;

  React.useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue, name, setValue]);

  return (
    <Controller
      name={name}
      render={({ field: { name, value, ref, ...control }, ...innerProps }) => {
        return (
          <TextField
            error={!!get(errors, name)}
            helperText={get(errors, name)?.message || helperText}
            label={label}
            variant="outlined"
            fullWidth={true}
            required={required}
            value={value}
            {...control}
            {...textFieldProps}
            {...innerProps}
            onBlur={(e) => {
              if (e.target.value === '') {
                setValue(name, null);
              } else {
                setValue(name, e.target.value);
              }
            }}
            defaultValue={getValues(name)}
            inputRef={ref}
          />
        );
      }}
      rules={rules}
      control={control}
      defaultValue={getValues(name) || null}
    />
  );
};

export default FormTextBox;
